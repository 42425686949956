import { createOneAsicsApi } from '@asicsdigital/oneasics-blocks';

import ENVIRONMENTS from './env';

export const CURRENT_ENVIRONMENT = ENVIRONMENTS[window.location.hostname];

export const ASICS_ID_BASE_URL = CURRENT_ENVIRONMENT.asicsIdBaseUrl;
export const CLIENT_ID = CURRENT_ENVIRONMENT.clientId ?? 'global_console';
export const REDIRECT_URI = encodeURIComponent(CURRENT_ENVIRONMENT.redirectUri);

const API_BASE_URL = CURRENT_ENVIRONMENT.apiBaseUrl;

export const { mfaApi, oauthApi, storesApi, usersApi, clientsApi } = createOneAsicsApi({
  baseUrl: API_BASE_URL,
  slice: 'global', // The slice the token is in
  tokenName: 'userToken', // The name of the token in the slice
});
